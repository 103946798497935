import React, { useState } from 'react';
import styled from 'styled-components';

import {
    Link,
    useLocation
  } from "react-router-dom";

import Button from '@material-ui/core/Button';
import Page from '../components/Page';

import { device } from './../settings';
import { Event } from './../utils/tracking';

const Info = ({ color, accent, closeNav }) => {
    const [legalOpen, setLegalOpen] = useState(false);
    let location = useLocation();
    let { pathname } = location;

    const toggleDisclaimer = () => {
        if (legalOpen) {
            setLegalOpen(false);
            return;
        }

        // Track the legal disclaimer button click event
        Event("Buttons", "Disclaimer - Click", "Legal Disclaimer");
        setLegalOpen(true);
    }

    const handleInstaClick = () => {
        // Track the the Instagram click event
        Event("Links", "Instagram - Click", "Lizzie Games on IG");
    }

    return (
        <Page title="What is This?" gaTitle="About" color={color} accent={accent} closeNav={closeNav}>
            <p><GameTitle>LizzieGames</GameTitle> is an unofficial<sup>*</sup> game portal for fans of the Disney Channel original series,&nbsp; 
            <a href="https://en.wikipedia.org/wiki/Lizzie_McGuire" target="_blank" rel="noopener"><em>Lizzie McGuire</em></a>. 
            Launched in celebration of the iconic series' 20th anniversary, this project was originally created to stir excitement for the much-anticipated Disney+ reboot. (* cue sad violins 😭 *)</p>

            <p>While it appears we won't be getting the reboot as hoped, 
                LizzieGames will be sticking around to recapture some of the fun and 
                nostalgia of the original series in new, interactive ways. Be sure to follow <a href="https://www.instagram.com/lizzie.games/" target="_blank" rel="noopener" onClick={handleInstaClick}>@lizzie.games</a> on Instagram 
                and check out the <Link to="/games" active={pathname === '/games'}>Games</Link> page for some Lizzie-themed fun! 🎉</p>

            <DisclaimerButton
                size="small"
                variant="text"
                color={'#EA4388'}
                hoverColor={accent}
                onClick={toggleDisclaimer}
            >
                <sup>*</sup> Obligatory legal disclaimer
            </DisclaimerButton>
            <Disclaimer legalOpen={legalOpen}>
                <p>Just to reiterate for whatever it's worth legally (probably nothing), but "LizzieGames" was 
                created by a fan for other fans. It is interactive fan art that is <strong>not</strong> associated with or endorsed by Disney, Disney Channel or Disney+. 
                It is also <strong>not</strong> being used for commercial or otherwise nefarious purposes (i.e. no money has been made off of these games or ever will be). "Lizzie McGuire", cartoon Lizzie and all other characters 
                referenced within this content are the sole intellectual property of The Walt Disney Company.</p>
                <p>TL;DR: This is all just for fun, please don't sue!</p>
            </Disclaimer>
        </Page>
    );
}

export default Info;

const GameTitle = styled.span`
    font-family: 'Montserrat';
    font-weight: 700;
    color: #425CAD;
`;

const DisclaimerButton = styled(Button)`
    padding-left: 0;
    padding-right: 0;
    border-radius: 0;
    text-transform: none;
    font-size: 13px;
    color: #425CAD;

    // @media ${device.tablet} { 
    //     font-size: 13px;
    // }

    &:hover {
        background-color: transparent;
        color: ${props => props.color};
    }

    sup {
        position: relative;
        top: 4px;
        font-weight: 600;
        margin-right: 3px;
        font-size: 16px;
        color: inherit;

        @media ${device.tablet} { 
            font-size: 18px;
            top: 5px;
        }

        @media ${device.laptop} { 
            top: 4px;
        }
    }
`;

const Disclaimer = styled.cite`
    display: block;
    overflow: hidden;
    height: ${props => props.legalOpen ? `auto` : `0` };

    & > p {
        margin-bottom: 10px;
        font-style: italic;
        font-size: 13px;
        line-height: 1.65;
        color: #333;
    }
`;