import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Menu from '../components/Menu';

import Logo from '../components/Logo';
import lizzieImage from './../assets/images/toon_lizzie.png';

import { device } from './../settings';
import { Pageview } from './../utils/tracking';

const Home = ({ menuColor, menuHoverColor, logoBorder, navOpen }) => {
    const [showLogo, setShowLogo] = useState(false);
    const history = useHistory();

    // Show the logo & menu
    useEffect(() => {
        setTimeout(() => {
            setShowLogo(true);
        }, 600);

        return () => setShowLogo(false);
    }, []);

    // Track Homepage views 
    useEffect(() => {
        if (!navOpen) {
            Pageview('Home');
        }
    }, [navOpen]);

    // Toggle back to home if header is clicked
    const handleLogoClick = () => {
        if (navOpen) {
            history.push('/');
        } else {
            history.push('/games');
        }
    }

    return (
        <HomeWrap navOpen={navOpen}>
            <Header showLogo={showLogo}>
                <LizzieImage src={lizzieImage} alt="Lizzie McGuire" navOpen={navOpen} />
                <Logo borderColor={logoBorder} toggleNav={handleLogoClick} />
                <Menu 
                    navOpen={navOpen} 
                    color={menuColor}
                    hoverColor={menuHoverColor}
                />
            </Header>
        </HomeWrap>
    );
}

export default Home;

const HomeWrap = styled.div`
    position: absolute;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: ${props => props.navOpen ? `18%` : `100%`};
    padding: 0 20px;
    transition: all 400ms ease;
    box-shadow: 0px 0px 5px 5px rgba(0,0,0,0.3);
    z-index: 9;

    @media ${device.laptop} { 
        height: 100%;
        width: ${props => props.navOpen ? `50%` : `100%`};
        box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.3);
    }
`;

const Header = styled.header`
    position: relative;
    max-width: 300px;
    width: 100%;
    margin: 0 auto;
    opacity: ${props => props.showLogo ? '1' : '0' };
    transition: opacity 600ms ease;

    @media ${device.tablet} { 
        max-width: 400px;
    }

    @media ${device.laptopL} and (max-height: 1024px) { 
        max-width: 430px;
    }
`;

const LizzieImage = styled.img`
    display: block;
    position: relative;
    z-index: 9;
    top: 15px;
    left: 35px;
    width: 80%;
    transform: rotate(2deg);
    transition: all 200ms ease;
    margin: auto 0;
    margin-top: ${props => props.navOpen ? `-150px` : `-50px` };
    opacity: ${props => props.navOpen ? `0` : `1` };

    @media ${device.tablet} { 
        margin-top: ${props => props.navOpen ? `-225px` : `-100px` };
        top: 21px;
        width: 90%;
    }

    @media ${device.laptop} { 
        margin-top: ${props => props.navOpen ? `-100px` : `-100px` };
        opacity: 1;
    }

    @media ${device.laptopL} and (max-height: 1024px) { 
        margin-top: ${props => props.navOpen ? `-50px` : `-50px` };
        top: 22px;
    }

    @media ${device.desktop} { 
        top: 23px;
        width: 95%;
        left: 20px;
        margin-top: ${props => props.navOpen ? `-30px` : `-30px` };
    }
`;