import React from 'react';

import Page from '../components/Page';
import GameCarousel from '../components/GameCarousel';

const Games = ({ color, accent, closeNav }) => {
    return (
        <Page 
            title="Games & Activities" 
            gaTitle="Games"
            color={color} 
            accent={accent} 
            closeNav={closeNav}
            paddingTight={true}
            frame
        >
            <GameCarousel />
        </Page>
    );
}

export default Games;