import React from 'react';
import styled from 'styled-components';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';

import GamePromo from '../components/GamePromo';

import gameData from '../assets/data';

import { device } from '../settings';

const GameCarousel = () => {
    const { games } = gameData;

    return (
        <>
            {games && (
                <StyledCarousel
                    showStatus={false}
                    showThumbs={false}
                    transitionTime={500}
                    interval={15000}
                    autoPlay
                    swipeable
                    emulateTouch
                    useKeyboardArrows
                    infiniteLoop
                    stopOnHover
                    renderArrowPrev={(onClickHandler, hasPrev, label) =>
                        hasPrev && (<StyledArrow type="button" className="left" onClick={onClickHandler} title={label} />)
                    }
                    renderArrowNext={(onClickHandler, hasNext, label) =>
                        hasNext && (<StyledArrow type="button" className="right" onClick={onClickHandler} title={label} />)
                    }
                >
                    {games.map(game => {
                        return (
                            <GamePromo key={game.id} game={game} />
                        );
                    })}
                </StyledCarousel>  
            )}
        </>
    );
}

export default GameCarousel;

const StyledCarousel = styled(Carousel)`
    max-width: 100%;

    & .carousel.carousel-slider {
        overflow: visible;
    }

    & .carousel .slide {
        text-align: left;
        background: none;
    }

    & .carousel .control-dots {
        top: 100%;
        margin: 0 auto;
        padding: 0.5rem 0;
    }

    & .carousel .control-dots .dot {
        box-shadow: none;
        background: #425CAD;
    }

    & .carousel .control-dots .dot.selected, 
    & .carousel .control-dots .dot:hover {
        background: #425CAD;
    }

    & .carousel .control-dots .dot:focus {
        outline: 0;
    }
`;

const StyledArrow = styled('div')`
    position: absolute;
    top: calc(50% - 1.75rem);
    line-height: 1;
    width:  1.25rem;
    height: 1.25rem;
    border-top-right-radius: 40%;
    background-color: #fff;
    text-align: left;
    margin: 1rem;
    transition: all .45s ease;
    cursor: pointer;
    z-index: 9;
    opacity: 0.5;
    // box-shadow: 0px 0px 0px 2px rgb(0,0,0,0.1);
    display: none;

    @media ${device.tablet} { 
        display: inline-block;
    }

    @media ${device.laptop} { 
        display: none;
    }

    @media ${device.laptopL} { 
        display: inline-block;
    }

    &.left  {
        left: -60px;
        transform: rotate(210deg) skewX(-30deg) scale(1,.866); 
        transform-origin: 45% 75%; 
    }
    &.right { 
        right: -60px;
        transform: rotate(30deg) skewX(-30deg) scale(1,.866); 
        transform-origin: 45% 75%; 
    }

    &:hover {
        opacity: 1;
    }

    &:before,
    &:after {
        content: '';
        position: absolute;
        background-color: inherit;
        width:  100%;
        height: 100%;
        border-top-right-radius: 50%;
    }

    &:before { transform: rotate(-135deg) skewX(-45deg) scale(1.414,.707) translate(0,-50%); }
    &:after  { transform: rotate(135deg) skewY(-45deg) scale(.707,1.414) translate(50%); }
`;