import React, { useEffect } from 'react';
import { hot } from 'react-hot-loader';
import {
  BrowserRouter as Router,
  Route,
  useLocation
} from "react-router-dom";
import { AnimatedSwitch } from 'react-router-transition';

import Div100vh from 'react-div-100vh'
import { ThemeProvider, StylesProvider } from '@material-ui/core/styles';
import styled from 'styled-components';
import { theme } from './settings';

import Home from './views/Home';
import Games from './views/Games';
import Info from './views/Info';
import Contact from './views/Contact';

import { device } from './settings';

import gameData from './assets/data';

const { primary, secondary, accent, dark } = theme.customPalettes[5];

const AppInner = () => {
  const { pathname } = useLocation();

  // Preload Game Images

  useEffect(() => {
    // console.log(gameData);
    for (const src in gameData.allImages) {
      let image = new Image();
      image.src = src;
    }
  }, []);

  return (
    <AppWrapper>
      {/* <BackgroundOverlay color={bkg} /> */}
      <RouteWrapper navOpen={pathname !== "/"}>
          <AnimatedSwitch
            atEnter={{ opacity: 0 }}
            atLeave={{ opacity: 0 }}
            atActive={{ opacity: 1 }}
            className="switch-wrapper"
          >
            <Route path="/games" children={<Games color={primary} accent={dark} />} />
            {/* <Route path="/latest" children={<News color={secondary} accent={dark} closeNav={closeNav} />} /> */}
            <Route path="/about" children={<Info color={'#3a9bcd'} accent={dark} />} />
            <Route path="/connect" children={<Contact color={secondary} accent={dark} />} />
          </AnimatedSwitch>
      </RouteWrapper>
      <Home 
        menuColor={accent}
        menuHoverColor={'#fff'}
        logoBorder={dark}
        navOpen={pathname !== "/"}
      />
    </AppWrapper>
  );
}

const App = () => {
  // console.log("App rendering.");

  // APP

  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Router>
          <AppInner />
        </Router>
      </ThemeProvider>
    </StylesProvider>
  );
}

export default hot(module)(App);

const AppWrapper = styled(Div100vh)`
  position: relative;
  max-width: 100vw;
  width: 100%;
`;

const BackgroundOverlay = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: ${props => props.color};
    opacity: 0.9;
`;

const RouteWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: ${props => props.navOpen ? `82%` : `0%`};
  transition: all 400ms ease;

  @media ${device.laptop} { 
    top: 0;
    left: auto;
    height: 100%;
    width: ${props => props.navOpen ? `50%` : `0%`};
  }

  &>div {
    height: 100%;
    width: 100%;
  }

  .switch-wrapper {
    position: relative;
  }
   
  .switch-wrapper > div {
    position: absolute;
    height: 100%;
    width: 100%;
  }
`;