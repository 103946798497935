import React, { useEffect } from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import stripesImage from './../assets/images/stripes2.jpg';
import { useHistory } from "react-router-dom";

import Paper from '@material-ui/core/Paper';

import { device } from '../settings';
import { Pageview } from '../utils/tracking';

const Page = ({ title, gaTitle, color, accent, frame, children }) => {
    const history = useHistory();

    // Send a pageview event any time the location changes
    useEffect(() => {
        Pageview(gaTitle);
    }, [gaTitle]);

    const handleBackClick = () => {
        history.push("/");
    };

    return (
        <PageWrapper background={stripesImage}>
            <BackgroundOverlay color={color} />
            <Inner frame={frame}>
                <PageTitle>
                    <Heading color={'#EA4388'} accent={accent}>{title}</Heading>
                </PageTitle>
                {frame ? (
                    <>
                        <FrameTop elevation={6} />
                            {children}
                        <FrameBottom />
                    </>
                ) : (
                    <PageContent elevation={6}>
                        {children}
                    </PageContent>
                )}
                <BackButton 
                    aria-label="Back to Home" 
                    variant="contained"
                    size="large"
                    color="secondary"
                    onClick={handleBackClick}
                    startIcon={<ArrowBackIcon />}
                >Back</BackButton>
            </Inner>
        </PageWrapper>
    );
}

export default Page;

const PageWrapper = styled.div`
    background-image: url(${props => props.background});
    position: relative;
    height: 100%;
    width: 100vw;

    @media ${device.laptop} { 
        width: 50vw;
    }
`;

const Inner = styled.div`
    position: relative;
    max-width: 650px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 20px;
    margin: 0 auto;
    overflow-y: ${props => props.frame ? 'visible' : 'auto'};

    @media ${device.tablet} { 
        justify-content: center;
    }

    @media ${device.laptop} {
        max-width: 750px;
    }

    @media ${device.laptop} and ${device.laptopH} { 
        justify-content: flex-start;
    }

    @media ${device.laptopL} and ${device.desktopH} { 
        justify-content: center;
    }

    // @media ${device.desktop} {
    //     max-width: 700px;
    // }
`;

const PageTitle = styled(Paper)`
    position: relative;
    padding: 15px 25px 0px;
    border-top-right-radius: 1.5rem;
    border-top-left-radius: 1.5rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: none;
    z-index: 9;

    @media ${device.tablet} { 
        padding: 20px 35px 0px;
    }

    @media ${device.desktop} { 
        padding: 25px 50px 0px;
    }
`;

const Heading = styled.h2`
    border-bottom: 4px dotted;
    border-color: ${props => props.accent ? props.accent : props.color };
    color: ${props => props.color};
    letter-spacing: 2px;
    padding-bottom: 5px;
    font-family: 'Montserrat';
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
    margin: 0;

    @media ${device.tablet} { 
        font-size: 18px;
        padding-bottom: 10px;
        border-bottom-width: 6px;
    }

    @media ${device.laptop} { 
        font-size: 20px;
    }

    @media ${device.desktop} { 
        font-size: 22px;
    }
`;

const PageContent = styled(Paper)`
    position: relative;
    margin: 0 auto;
    padding: 35px 25px;
    width: 100%;
    border-top-left-radius: 0;

    @media ${device.tablet} { 
        padding: 45px 50px 50px 35px;
    }

    @media ${device.desktop} { 
        padding: 45px 50px 50px;
    }
`;

const FrameTop = styled(Paper)`
    position: relative;
    margin: 0 auto;
    padding: 20px 0 0;
    width: 100%;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    @media ${device.tablet} { 
        padding-top: 30px;
    }

    @media ${device.desktop} { 
        padding-top: 40px;
    }
`;

const FrameBottom = styled(Paper)`
    position: relative;
    margin: 0 auto;
    padding: 20px 0;
    width: 100%;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
`;

const BackgroundOverlay = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: ${props => props.color};
    opacity: 0.9;
`;

const BackButton = styled(Button)`
    min-width: 100px;
    min-height: 40px;
    margin-top: 15px;
    padding-top: 10px;
    font-size: 14px;
    font-family: 'Montserrat';

    @media ${device.tablet} { 
        font-size: 16px;
        margin-top: 30px;
    }

    // background-color: #264192;
    // &:hover {
    //     background-color: #292672;
    // }
`;