import React from 'react';
import { useTheme } from '@material-ui/styles';
import styled from 'styled-components';
import settings from '../settings';

import { device } from './../settings';

const Logo = ({ toggleNav, borderColor }) => {
    const theme = useTheme();

    const { primary, secondary, accent } = theme.customPalettes[5];

    const { title } = settings;
    const maxLogoWidth = 595;

    return (
        <LogoWrap borderColor={borderColor} onClick={toggleNav}>
            <WordMark>
                    <LogoInner>
                        <TextWrap viewBox={`0 0 ${maxLogoWidth} 65`} maxwidth={maxLogoWidth}>
                            <g id="logo-group" aria-label={title}>
                                <filter id="dropshadow" x="-20%" y="-20%" width={maxLogoWidth*2} height="120">
                                    <feGaussianBlur stdDeviation="3"/>
                                </filter>
                                <g aria-label="L" transform="translate(0.1977990000001 58.856) scale(1.0) translate(-267.88625 0)"> <path className="shadow" d="M97.91162,125.19405H87.75928c-2.59277,0-4.75293-2.16016-4.75293-4.75293V79.5417 c0-2.5918,2.16016-4.75195,4.75293-4.75195c2.66406,0,4.82422,2.16016,4.82422,4.75195v36.0752h5.32813 c2.66406,0,4.75293,2.16016,4.75293,4.82422C102.66455,123.03389,100.57568,125.19405,97.91162,125.19405z" transform="translate(184.8799 -125.18975)" strokeWidth="0" strokeLinejoin="miter" strokeMiterlimit="2" fill={primary} stroke={primary}></path> <path className="shadow" d="M107.48877,120.44112c0-2.66406,2.08789-4.82422,4.75195-4.82422s4.75293,2.16016,4.75293,4.82422 c0,2.59277-2.08887,4.75293-4.75293,4.75293S107.48877,123.03389,107.48877,120.44112z" transform="translate(184.8799 -125.18975)" strokeWidth="0" strokeLinejoin="miter" strokeMiterlimit="2" fill={accent} stroke={accent}></path> </g>
                                <g aria-label="I" transform="translate(51.9771385000001 57.754400000000004) scale(1.0) translate(-308.40312 1.3)"> <path className="shadow" d="M95.21143,78.2458c0-2.59277,2.16016-4.75293,4.75293-4.75293c2.66406,0,4.82422,2.16016,4.82422,4.75293 c0,2.66406-2.16016,4.82422-4.82422,4.82422C97.37158,83.07002,95.21143,80.90987,95.21143,78.2458z" transform="translate(213.19169 -125.18887)" strokeWidth="0" strokeLinejoin="miter" strokeMiterlimit="2" fill={secondary} stroke={secondary}></path> <path className="shadow" d="M95.21143,120.44112V92.57491c0-2.5918,2.16016-4.75195,4.75293-4.75195 c2.66406,0,4.82422,2.16016,4.82422,4.75195v27.86621c0,2.59277-2.16016,4.75293-4.82422,4.75293 C97.37158,125.19405,95.21143,123.03389,95.21143,120.44112z" transform="translate(213.19169 -125.18887)" strokeWidth="0" strokeLinejoin="miter" strokeMiterlimit="2" fill={primary} stroke={primary}></path> </g>
                                <g aria-label="Z" transform="translate(88.22815950000006 58.856) scale(1.0) translate(-330.65138 0)"> <path className="shadow" d="M101.07981,125.19405H84.59055c-1.80078,0-3.45703-1.00879-4.24902-2.59277 c-0.79199-1.65625-0.64746-3.59961,0.43262-5.04004l24.76953-33.19531H85.95774c-2.66406,0-4.82422-2.16016-4.82422-4.82422 c0-2.5918,2.16016-4.75195,4.82422-4.75195h29.09082c1.80078,0,3.45605,1.00781,4.24902,2.66406 c0.79199,1.58398,0.64746,3.52832-0.43262,4.96875l-24.77051,33.2666h6.98535c2.5918,0,4.75195,2.08789,4.75195,4.75195 C105.83176,123.03389,103.67161,125.19405,101.07981,125.19405z" transform="translate(250.812247 -125.18976)" strokeWidth="0" strokeLinejoin="miter" strokeMiterlimit="2" fill={primary} stroke={primary}></path> <path className="shadow" d="M120.16087,120.44112c0,2.59277-2.16016,4.75293-4.75195,4.75293 c-2.66406,0-4.82422-2.16016-4.82422-4.75293c0-2.66406,2.16016-4.75195,4.82422-4.75195 C118.00071,115.68916,120.16087,117.77705,120.16087,120.44112z" transform="translate(250.812247 -125.18976)" strokeWidth="0" strokeLinejoin="miter" strokeMiterlimit="2" fill={secondary} stroke={secondary}></path> </g>
                                <g aria-label="Z" transform="translate(148.36034700000005 58.856) scale(1.0) translate(-380.99513 0)"> <path className="shadow" d="M101.07981,125.19405H84.59055c-1.80078,0-3.45703-1.00879-4.24902-2.59277 c-0.79199-1.65625-0.64746-3.59961,0.43262-5.04004l24.76953-33.19531H85.95774c-2.66406,0-4.82422-2.16016-4.82422-4.82422 c0-2.5918,2.16016-4.75195,4.82422-4.75195h29.09082c1.80078,0,3.45605,1.00781,4.24902,2.66406 c0.79199,1.58398,0.64746,3.52832-0.43262,4.96875l-24.77051,33.2666h6.98535c2.5918,0,4.75195,2.08789,4.75195,4.75195 C105.83176,123.03389,103.67161,125.19405,101.07981,125.19405z" transform="translate(301.155997 -125.18976)" strokeWidth="0" strokeLinejoin="miter" strokeMiterlimit="2" fill={primary} stroke={primary}></path> <path className="shadow" d="M120.16087,120.44112c0,2.59277-2.16016,4.75293-4.75195,4.75293 c-2.66406,0-4.82422-2.16016-4.82422-4.75293c0-2.66406,2.16016-4.75195,4.82422-4.75195 C118.00071,115.68916,120.16087,117.77705,120.16087,120.44112z" transform="translate(301.155997 -125.18976)" strokeWidth="0" strokeLinejoin="miter" strokeMiterlimit="2" fill={accent} stroke={accent}></path> </g>
                                <g aria-label="I" transform="translate(210.02136149999995 57.754400000000004) scale(1.0) translate(-433.1375 1.3)"> <path className="shadow" d="M95.21143,78.2458c0-2.59277,2.16016-4.75293,4.75293-4.75293c2.66406,0,4.82422,2.16016,4.82422,4.75293 c0,2.66406-2.16016,4.82422-4.82422,4.82422C97.37158,83.07002,95.21143,80.90987,95.21143,78.2458z" transform="translate(337.92607 -125.18887)" strokeWidth="0" strokeLinejoin="miter" strokeMiterlimit="2" fill={secondary} stroke={secondary}></path> <path className="shadow" d="M95.21143,120.44112V92.57491c0-2.5918,2.16016-4.75195,4.75293-4.75195 c2.66406,0,4.82422,2.16016,4.82422,4.75195v27.86621c0,2.59277-2.16016,4.75293-4.82422,4.75293 C97.37158,125.19405,95.21143,123.03389,95.21143,120.44112z" transform="translate(337.92607 -125.18887)" strokeWidth="0" strokeLinejoin="miter" strokeMiterlimit="2" fill={primary} stroke={primary}></path> </g>
                                <g aria-label="E" transform="translate(247.18920949999995 58.856) scale(1.0) translate(-456.46438 0)"> <path className="shadow" d="M87.75879,125.19405c-2.5918,0-4.75195-2.16016-4.75195-4.75293V79.5417 c0-2.5918,2.16016-4.75195,4.75195-4.75195h9.9375c2.5918,0,4.75195,2.16016,4.75195,4.75195 c0,2.66406-2.16016,4.82422-4.75195,4.82422h-5.1123V95.3835h16.70508c2.66406,0,4.75195,1.94434,4.75195,4.6084 c0,2.5918-2.08789,4.53613-4.75195,4.53613H92.58398v11.08887h19.65723c2.66406,0,4.75195,2.16016,4.75195,4.82422 c0,2.59277-2.08789,4.75293-4.75195,4.75293H87.75879z" transform="translate(373.45754 -125.18975)" strokeWidth="0" strokeLinejoin="miter" strokeMiterlimit="2" fill={primary} stroke={primary}></path> <path className="shadow" d="M112.02539,84.36592c-2.66406,0-4.82422-2.16016-4.82422-4.82422c0-2.5918,2.16016-4.75195,4.82422-4.75195 c2.5918,0,4.75195,2.16016,4.75195,4.75195C116.77734,82.20576,114.61719,84.36592,112.02539,84.36592z" transform="translate(373.45754 -125.18975)" strokeWidth="0" strokeLinejoin="miter" strokeMiterlimit="2" fill={secondary} stroke={secondary}></path> </g>
                                <g aria-label="G" transform="translate(299.8387365 57.754400000000004) scale(1.0) translate(-498.005 1.3)"> <path className="shadow" d="M102.84473,73.49287c4.82422,0,11.59277,0.93652,17.06543,5.40137 c2.01563,1.65527,2.16016,4.53613,0.64746,6.69629c-1.43945,2.01563-4.32031,1.72754-6.62402,0.50391 c-3.02441-1.51172-6.55273-3.02441-11.08887-3.02441c-9.36133,0-16.92188,7.56055-16.92188,16.92188 c0,9.36035,7.56055,16.9209,16.92188,16.9209c3.74414,0,8.13672-1.15137,11.30469-2.95215v-9.93652 c0-2.59277,2.08789-4.75293,4.75195-4.75293c2.66504,0,4.75293,2.16016,4.75293,4.75293v11.30469 c0,1.2959,0,2.30469-0.50391,3.38477c-0.43262,0.86328-1.15234,1.51172-2.08887,2.16016 c-4.75195,3.38379-11.95215,5.61621-18.2168,5.61621c-14.61719,0-26.49902-11.88086-26.49902-26.49805 C76.3457,85.30244,88.22754,73.49287,102.84473,73.49287z" transform="translate(421.6593 -125.18887)" strokeWidth="0" strokeLinejoin="miter" strokeMiterlimit="2" fill={secondary} stroke={secondary}></path> <path className="shadow" d="M104.64453,99.27119c2.5918,0,4.75195,2.16016,4.75195,4.75293c0,2.66406-2.16016,4.82422-4.75195,4.82422 c-2.66406,0-4.82422-2.16016-4.82422-4.82422C99.82031,101.43135,101.98047,99.27119,104.64453,99.27119z" transform="translate(421.6593 -125.18887)" strokeWidth="0" strokeLinejoin="miter" strokeMiterlimit="2" fill={accent} stroke={accent}></path> </g>
                                <g aria-label="A" transform="translate(362.42246 58.856) scale(1.0) translate(-551.23291 0)"> <path className="shadow" d="M117.13755,104.6003l-12.81689-27.07422c-0.79199-1.65625-2.52051-2.73633-4.32031-2.73633 c-1.87207,0-3.52832,1.08008-4.32031,2.73633l-19.44189,40.82715c-1.08008,2.37598-0.07178,5.25684,2.3042,6.4082 c2.37598,1.08008,5.18457,0.07227,6.33643-2.071l4.53662-9.50488h24.19385c1.65625,0,3.09619-0.86426,3.96045-2.16016 C119.22593,108.34444,117.92954,106.25655,117.13755,104.6003z M93.057,104.74385l6.69678-14.11328l6.76855,14.11328H93.057z" transform="translate(475.427091 -125.18975)" strokeWidth="0" strokeLinejoin="miter" strokeMiterlimit="2" fill={secondary} stroke={secondary}></path> <path className="shadow" d="M124.19419,120.44112c0,2.59277-2.16016,4.75293-4.82422,4.75293 c-2.59229,0-4.75244-2.16016-4.75244-4.75293c0-2.66406,2.16016-4.82422,4.75244-4.82422 C122.03403,115.6169,124.19419,117.77705,124.19419,120.44112z" transform="translate(475.427091 -125.18975)" strokeWidth="0" strokeLinejoin="miter" strokeMiterlimit="2" fill={primary} stroke={primary}></path> </g>
                                <g aria-label="M" transform="translate(428.7954240000001 58.86353695) scale(1.0) translate(-608.91875 0)"> <path className="shadow" d="M117.89355,95.59932l-14.04102,27.14648c-0.86426,1.51172-2.44824,2.44824-4.17676,2.44824 c-1.72754,0-3.31152-0.93652-4.17578-2.52051L82.10645,96.03096v24.41016c0,2.59277-2.16016,4.75293-4.82422,4.75293 c-2.59277,0-4.75293-2.16016-4.75293-4.75293V79.5417c0-2.16016,1.44043-4.03223,3.60059-4.6084 c2.08789-0.50391,4.32031,0.43262,5.3291,2.37598l18.28906,34.70703l18.79395-34.77832 c1.00781-1.87207,3.24023-2.80859,5.32813-2.30469c2.08789,0.57617,3.60059,2.44824,3.60059,4.6084v26.57031 c0,2.59277-2.16016,4.75293-4.82422,4.75293c-2.59277,0-4.75293-2.16016-4.75293-4.75293V95.59932z" transform="translate(536.38945 -125.18939)" strokeWidth="0" strokeLinejoin="miter" strokeMiterlimit="2" fill={secondary} stroke={secondary}></path> <path className="shadow" d="M122.64648,125.19405c-2.59277,0-4.75293-2.16016-4.75293-4.75293 c0-2.66406,2.16016-4.82422,4.75293-4.82422c2.66406,0,4.82422,2.16016,4.82422,4.82422 C127.4707,123.03389,125.31055,125.19405,122.64648,125.19405z" transform="translate(536.38945 -125.18939)" strokeWidth="0" strokeLinejoin="miter" strokeMiterlimit="2" fill={primary} stroke={primary}></path> </g>
                                <g aria-label="E" transform="translate(504.4523345000001 58.856) scale(1.0) translate(-677.52688 0)"> <path className="shadow" d="M87.75879,125.19405c-2.5918,0-4.75195-2.16016-4.75195-4.75293V79.5417 c0-2.5918,2.16016-4.75195,4.75195-4.75195h9.9375c2.5918,0,4.75195,2.16016,4.75195,4.75195 c0,2.66406-2.16016,4.82422-4.75195,4.82422h-5.1123V95.3835h16.70508c2.66406,0,4.75195,1.94434,4.75195,4.6084 c0,2.5918-2.08789,4.53613-4.75195,4.53613H92.58398v11.08887h19.65723c2.66406,0,4.75195,2.16016,4.75195,4.82422 c0,2.59277-2.08789,4.75293-4.75195,4.75293H87.75879z" transform="translate(594.52004 -125.18975)" strokeWidth="0" strokeLinejoin="miter" strokeMiterlimit="2" fill={secondary} stroke={secondary}></path> <path className="shadow" d="M112.02539,84.36592c-2.66406,0-4.82422-2.16016-4.82422-4.82422c0-2.5918,2.16016-4.75195,4.82422-4.75195 c2.5918,0,4.75195,2.16016,4.75195,4.75195C116.77734,82.20576,114.61719,84.36592,112.02539,84.36592z" transform="translate(594.52004 -125.18975)" strokeWidth="0" strokeLinejoin="miter" strokeMiterlimit="2" fill={accent} stroke={accent}></path> </g>
                                <g aria-label="S" transform="translate(557.4222095000001 57.754400000000004) scale(1.0) translate(-719.44438 1.3)"> <path className="shadow" d="M90.67529,115.04073c0,2.66406-2.16016,4.75293-4.82422,4.75293 c-2.59277,0-4.75293-2.08887-4.75293-4.75293s2.16016-4.75195,4.75293-4.75195 C88.51514,110.28877,90.67529,112.37666,90.67529,115.04073z" transform="translate(638.34624 -125.18887)" strokeWidth="0" strokeLinejoin="miter" strokeMiterlimit="2" fill={primary} stroke={primary}></path> <path className="shadow" d="M102.48486,126.48994c-3.74512,0-8.78516-1.44043-8.78516-5.1123 c0-3.02441,2.01563-4.68066,4.39258-4.53613c2.44824,0.14355,5.1123,1.00781,7.7041-0.2168 c2.0166-0.93555,3.52832-2.73633,3.52832-5.25586c0-3.02441-3.38379-4.96875-10.72852-7.63281 c-6.625-2.37598-16.56152-5.32813-16.63379-15.19336c0-3.74414,1.44043-7.27246,4.10449-10.00879 c2.30469-2.30469,6.55273-5.04102,13.96973-5.04102c8.42383,0,14.04102,4.46484,15.91309,7.12891 c1.2959,1.72852,1.08008,4.03223-0.21582,5.68848c-1.72852,2.16016-4.32031,1.94434-6.12109,0.36035 c-1.2959-1.15234-4.32031-4.17676-9.86426-4.17676c-4.8252,0-8.20898,2.52051-8.20898,6.04883 c0,2.071,2.52051,4.24805,10.00879,7.12891c9.2168,3.52832,17.35352,7.41602,17.35352,16.27344 C118.90186,119.07295,113.93311,126.48994,102.48486,126.48994z" transform="translate(638.34624 -125.18887)" strokeWidth="0" strokeLinejoin="miter" strokeMiterlimit="2" fill={secondary} stroke={secondary}></path> </g>
                            </g>
                        </TextWrap>
                    </LogoInner>
            </WordMark>
        </LogoWrap>
    );
}

export default Logo;

const LogoWrap = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: baseline;
    font-family: 'Khula';
    width: 100%;
    padding: 10px 0 0px;
    border: 6px solid ${props => props.borderColor};
    border-radius: 0;
    border-right: 0;
    border-left: 0;
    cursor: pointer;

    @media ${device.tablet} { 
        padding: 15px 0 10px;
    }
`;

const LogoInner = styled.div`
    position: relative;
    text-align: right;
`;

const WordMark = styled.div`
    width: 100%;
`;

const TextWrap = styled.svg`
    position: relative;
    max-width: ${props => `${props.maxwidth}px`};
    width: 100%;
    box-sizing: border-box;
    z-index: -1;
    // .shadow {
    //     filter: url('#dropshadow');
    // }
`;