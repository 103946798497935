const data = {
	games: [
		{
			_id: "4",
			title: "Digital Bean Virtual Chat",
			date: "July 16, 2023",
			thumbnail: "digital-bean",
			description:
				"Ever wanted to visit the iconic Digital Bean cafe with friends? Check out the Digital Bean Virtual Chat for some immersive Lizzie nostalgia!",
			tags: ["3D chat", "webVR", "social VR"],
			type: "other",
			path: "/digital-bean",
			url: "https://lizzie.games/digital-bean",
			color: "#d67312",
			checkDevice: true,
		},
		{
			_id: "1",
			title: "Lizzie's Mix n' Match Madness",
			date: "Jan. 18, 2021",
			thumbnail: "mix-n-match",
			description:
				"How well do you know your classic Lizzie McGuire quotes? Play 'Lizzie's Mix n' Match Madness' to find out!",
			tags: ["cards", "matching", "memory", "trivia", "timed"],
			type: "game",
			path: "/mix-match",
			url: "/mix-match",
			color: "#41006f" // #292672
		},
		{
			_id: "2",
			title: "Lizzie's Wacky Whac-A-Matt",
			date: "Mar. 6, 2021",
			thumbnail: "whac-a-matt",
			description:
				"How many pesky little brothers can you fend off before time runs out? Play 'Lizzie's Wacky Whac-A-Matt' to find out!",
			tags: ["whac-a-mole", "timed"],
			type: "game",
			path: "/whac-matt",
			url: "/whac-matt",
			color: "#374e00"
		},
		{
			_id: "3",
			title: "Lizzie's Picture-Perfect Puzzler",
			date: "May 29, 2021",
			thumbnail: "puzzler",
			description:
				"How quickly can you unscramble Lizzie's photos? Play 'Lizzie's Picture-Perfect Puzzler' to find out!",
			tags: ["jigsaw puzzle", "sliding puzzle", "timed"],
			type: "game",
			path: "/puzzler",
			url: "/puzzler",
			color: "#a3141b"
		}
	],
	allImages: {
		mixMatch: require("./images/game_thumbs/mix-n-match.jpg"),
		whacMatt: require("./images/game_thumbs/whac-a-matt.jpg"),
		puzzler: require("./images/game_thumbs/puzzler.jpg"),
		digitalBean: require("./images/game_thumbs/digital-bean.jpg")
	}
};

export default data;