import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import './index.css';

import { initGA } from './utils/tracking';

// Initialize GA tracking
initGA('G-LPETN525EL');

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);