import React, { useState, useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { isMobile } from 'react-device-detect';

import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import ArrowCircleRightIcon from '@material-ui/icons/ArrowForward';

import { device } from './../settings';
import { Event } from './../utils/tracking';

import DeviceCheck from './DeviceCheck';

const GamePromo = ({ game }) => {
    const { title, date, thumbnail, description, tags, type, url, color, checkDevice, disabled, labelOverride } = game;
    const [deviceCheckOpen, setDeviceCheckOpen] = useState(false);
    const [gameImg, setGameImg] = useState(null);

    const showDeviceCheck = checkDevice && isMobile;
    const ctaLabel = type === "game" ? 'Play Game' : 'Check It Out';

    useEffect(() => {
        import(`./../assets/images/game_thumbs/${thumbnail}.jpg`)
        .then(image => { setGameImg(image.default) })
    }, [thumbnail]);

    const handleCTAclick = () => {
        // Track the cta button click event w/ the game title
        Event("Buttons", `${ctaLabel} - Click`, title);
    }

    const handleDeviceCheck = () => {
        handleCTAclick();
        Event("Modals", `Device Check`, title);
        setDeviceCheckOpen(true);
    }

    const launchGame = () => {
        window.open(url, "_blank");
    }

    return (
        <GameCard>
            <GameCardInner 
                color={color}
            >
                <GameThumbnail
                    image={gameImg}
                    title={title}
                />
                <GameContent>
                    <GameTitle gutterBottom variant="h5" component="h2">{ReactHtmlParser(title)}</GameTitle>
                    <GameSummary>{ReactHtmlParser(description)}</GameSummary>
                    <GameTags>
                        <TagHeader>tags:&nbsp;</TagHeader>
                        {tags && (
                            tags.map((tag, index) => {
                                return <TagLabel key={`tag-${tag}-${index}`}>{index ? ', ' : ''}{tag}</TagLabel>
                            })
                        )}
                    </GameTags>
                    <GameDate>released {date}</GameDate>
                    <PlayButton 
                        size="large" 
                        color="secondary"
                        variant="contained"
                        startIcon={type === "game" ? <PlayCircleFilledWhiteIcon fontSize="medium" /> : <ArrowCircleRightIcon fontSize="medium" />}
                        target="_blank"
                        disabled={disabled}
                        href={showDeviceCheck ? ``: url}
                        onClick={showDeviceCheck ? handleDeviceCheck : handleCTAclick}
                    >{labelOverride ?? ctaLabel}</PlayButton>
                    <DeviceCheck 
                        deviceCheckOpen={deviceCheckOpen} 
                        setDeviceCheckOpen={setDeviceCheckOpen} 
                        launchGame={launchGame} 
                    />
                </GameContent>
            </GameCardInner>
        </GameCard>
    );
};

export default GamePromo;

const GameCard = styled(Card)`
    display: flex;
    height: 100%;
`;

const GameCardInner = styled(CardActionArea)`
    background-color: ${props => props.color};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    cursor: default;

    @media ${device.tablet} { 
        flex-direction: row;
        align-items: flex-start;
    }

    @media ${device.laptop} { 
        flex-direction: column;
    }

    @media ${device.laptopL} { 
        flex-direction: row;
    }
`;

const GameThumbnail = styled(CardMedia)`
    transition: all 600ms ease;
    box-shadow: inset 0px 0px 10px 0px rgba(41,38,114, 0.7);
    background-size: 100%;
    background-position: center 25%;
    height: 130px;
    width: 100%;
    max-width: none;

    @media ${device.tablet} { 
        background-size: 180%;
        background-position: center;
        height: 100%;
        max-width: 40%;
    }

    @media ${device.laptop} { 
        opacity: 0.6;
        background-size: 130%;
        height: 150px;
        max-width: none;
    }

    @media ${device.laptopL} { 
        background-size: 180%;
        background-position center;
        height: 100%;
        max-width: 40%;
    }

    &:hover {
        background-size: 140%;

        @media ${device.tablet} { 
            background-size: 230%;
        }

        @media ${device.laptop} { 
            opacity: 1;
            background-size: 140%;
        }

        @media ${device.laptopL} { 
            background-size: 300%;
        }
    }
`;

const GameContent = styled(CardContent)`
    padding: 20px 25px 20px 20px;
    font-family: 'Khula';
    color: white;

    @media ${device.tablet} { 
        padding: 25px 30px;
        max-width: 60%;
    }

    @media ${device.laptop} { 
        max-width: none;
    }

    @media ${device.laptopL} { 
        padding: 25px 30px;
        max-width: 60%;
    }
`;

const GameTitle = styled(Typography)`
    font-family: 'Montserrat';
    font-size: 16px;
    font-weight: 700;
    color: inherit;
    margin-bottom: 5px;
    padding-bottom: 5px;

    @media ${device.tablet} { 
        margin-bottom: 10px;
        font-size: 18px;
    }

    @media ${device.laptop} { 
        font-size: 20px;
    }
`;

const GameSummary = styled.p`
    font-size: 13.5px;
    line-height: 1.65;
    letter-spacing: 0.5px;
    font-weight: 600;
    margin-bottom: 10px;

    @media ${device.tablet} { 
        margin-bottom: 15px;
        font-size: 15px;
    }

    @media ${device.laptop} and (max-width: 1920px) { 
        font-size: 16px;
    }
`;

const GameTags = styled.div`
    color: inherit;
    letter-spacing: 0.5px;
    font-size: 12.5px;
    margin-bottom: 5px;

    @media ${device.tablet} { 
        font-size: 13px;
    }

    @media ${device.laptop} { 
        font-size: 13.5px;
    }
`;

const TagHeader = styled.span`
    font-weight: 700;
`;

const TagLabel = styled.span`
    font-weight: 600;
`;

const GameDate = styled.div`
    font-size: 11.5px;
    font-weight: 400;
    font-style: italic;
    letter-spacing: 0.5px;
`;

const PlayButton = styled(Button)`
    margin-top: 20px;

    @media ${device.tablet} { 
        margin-top: 30px;
    }
`;