import ReactGA from 'react-ga4';

const localEnvt = window.location.hostname === 'localhost';
const stagingEnvt = window.location.hostname.indexOf('deploy-preview') !== -1;

export const initGA = (trackingID) => {
    if (localEnvt || stagingEnvt) return;  
    ReactGA.initialize(trackingID, {
        gtagOptions: {
            send_page_view: false
        }
    });
 }

 export const Pageview = (title) => {  
    if (localEnvt || stagingEnvt) return;  
    const path = window.location.pathname;
    ReactGA.send({
        hitType: 'pageview',
        title: title ?? path,
        page: path,
    });
}

/**
 * Event - Add custom tracking event.
 * @param {string} category 
 * @param {string} action 
 * @param {string} label
 * @param {integer} value
 */
export const Event = (category, action, label, value) => {
    if (localEnvt || stagingEnvt) return;  
    ReactGA.event({
        category: category,
        action: action,
        label: label,
        value: value
    });
};