import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';

import { theme } from './../settings';

const { primary } = theme.customPalettes[5];

const DeviceCheck = ({ deviceCheckOpen, setDeviceCheckOpen, launchGame }) => {
  const handleDeviceCheckAccept = () => {
    setDeviceCheckOpen(false);
    launchGame();
  }

  const handleDeviceCheckClose = () => {
    setDeviceCheckOpen(false);
  }

  return (
    <DeviceCheckDialog
      open={deviceCheckOpen}
      onClose={handleDeviceCheckClose}
      aria-labelledby="Mobile device warning"
      maxWidth="xs"
    >
      <DeviceCheckHeading>
        Hey, looks like you might be on your phone...
      </DeviceCheckHeading>
      <p>
        This experience is optimized for desktops/laptops, tablets and VR headsets and may not work on <br/>some mobile devices.
      </p>
      <DeviceCheckCTAs>
        <DeviceCheckButton
          size="large" 
          color="primary"
          variant="contained"
          onClick={handleDeviceCheckAccept}
        >I'll try it anyway!</DeviceCheckButton>
        <DeviceCheckButton
          size="large" 
          color="secondary"
          variant="contained"
          onClick={() => setDeviceCheckOpen(false)}
        >I'll come back later!</DeviceCheckButton>
      </DeviceCheckCTAs>
      <CloseButton 
        size="small" 
        color="secondary"
        variant="contained"
        onClick={handleDeviceCheckClose}
      >+</CloseButton>
    </DeviceCheckDialog>
  );
}

export default DeviceCheck;

const DeviceCheckDialog = styled(Dialog)`
    overflow: visible !important;

    .MuiDialog-paper {
      overflow: visible;
      padding: 30px 25px 25px;
      font-family: 'Khula';
      text-align: center;
    }

    p {
      font-size: 16px;
    }
`;

const DeviceCheckHeading = styled.h2`
    color: ${primary};
    letter-spacing: 1px;
    padding-bottom: 20px;
    font-family: 'Montserrat';
    font-size: 19px;
    font-weight: 700;
    line-height: 1.5;
    margin: 0;
`;

const DeviceCheckCTAs = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-top: 10px;
`;

const DeviceCheckButton = styled(Button)`
    margin-bottom: 15px;
    padding: 15px 22px;
`;

const CloseButton = styled(Button)`
    position: absolute;
    top: -15px;
    right: -15px;
    font-size: 40px;
    height: 40px;
    width: 40px;
    min-width: 0;
    letter-spacing: 1;
    transform: rotate(45deg);
`;

