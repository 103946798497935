import { createMuiTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import pink from '@material-ui/core/colors/pink';
import amber from '@material-ui/core/colors/amber';
import orange from '@material-ui/core/colors/orange';
import deepOrange from '@material-ui/core/colors/deepOrange';
import green from '@material-ui/core/colors/green';
import lightGreen from '@material-ui/core/colors/lightGreen';
import teal from '@material-ui/core/colors/teal';

const settings = {
    title: "LizzieGames",
}

const sizes = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '1920px',
    desktopL: '2560px'
}

const heights = {
    laptopH: '800px',
    desktopH: '1024px'
}

export const device = {
    mobileS: `(min-width: ${sizes.mobileS})`,
    mobileM: `(min-width: ${sizes.mobileM})`,
    mobileL: `(min-width: ${sizes.mobileL})`,
    tablet: `(min-width: ${sizes.tablet})`,
    laptop: `(min-width: ${sizes.laptop})`,
    laptopL: `(min-width: ${sizes.laptopL})`,
    laptopH: `(max-height: ${heights.laptopH})`,
    desktop: `(min-width: ${sizes.desktop})`,
    desktopL: `(min-width: ${sizes.desktop})`,
    desktopH: `(min-height: ${heights.laptopH})`
};

export const defaultTheme = createMuiTheme();
export const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#EA4388",
        },
        secondary: {
            main: "#425CAD",
            dark: "#303f9f",
        },
        info: {
            main: "#fdb827",
        },
        accent: {
            main: "#fff",
        },
    },
    customPalettes: [
        {
            white: "#fff"
        },
        {
            primary: "#f59935",
            secondary: "#e33e40",
            accent: pink[900],
            dark: red[200],
            bkg: orange[100]
        }, {
            primary: "#e33e40",
            secondary: "#32a99e",
            accent: "#33245d",
            dark: red[200],
            bkg: "#F7ECDA"
        },
        {
            primary: "#af0069",
            secondary: "#f6c065",
            accent: "#09015f",
            dark: "#09015f",
            bkg: "#55b3b1"
        },
        {
            primary: "#FFF",
            secondary: "#f6c065",
            accent: "#55b3b1",
            dark: "#09015f",
            bkg: "#d1274b"
        },
        {
            primary: "#EA4388",
            secondary: "#fdb827",
            accent: "#fff",
            dark: "#425CAD",
            bkg: "#09015f"
        }
    ],
    overrides: {
        MuiButton: {
            root: {
                borderRadius: '3rem'
            },
            label: {
                fontFamily: 'Montserrat',
                fontWeight: '600',
                letterSpacing: '2px',
            },
            contained: {
                color: 'white',
            },
            containedSizeLarge: {
                fontSize: '14px',
                letterSpacing: '2px',
                lineHeight: '0.9',

                [defaultTheme.breakpoints.up("sm")]: {
                    fontSize: '16px',
                }
            }
        },
        MuiButtonBase: {
            root: {
                '&:hover': {
                    borderBottom: '0'
                }
            }
        },
        MuiCard: {
            root: {
                borderRadius: '0',
                boxShadow: 'none'
            }
        },
        MuiContainer: {
            maxWidthSm: {
                maxWidth: '50rem !important',
            }
        },
        MuiPaper: {
            root: {

                [defaultTheme.breakpoints.down("sm")]: {
                    
                },
            },
            rounded: {
                borderRadius: '2rem'
            }
        }
    }
});

export default settings;