import React from 'react';
import styled from 'styled-components';

import { device } from './../settings';

import {
    Link,
    useLocation
  } from "react-router-dom";

const Menu = ({ color, hoverColor }) => {
    let location = useLocation();
    let { pathname } = location;

    return (
        <MenuWrap role="navigation">
            <MenuList color={color}>
                <li>
                    <MenuLink 
                    to="/" 
                    hoverColor={hoverColor}
                    >Home</MenuLink>
                </li>
                <li>
                    <MenuLink 
                    to="/games" 
                    active={pathname === '/games'}
                    hoverColor={hoverColor}
                    >Games</MenuLink>
                </li>
                {/* <li>
                    <MenuLink 
                    to="/latest" 
                    onClick={() => handleNavOpen('Updates')} 
                    active={activePage === 'Updates' ? true : false}
                    hoverColor={hoverColor}
                    >Latest</MenuLink>
                </li> */}
                <li>
                    <MenuLink 
                    to="/about" 
                    active={pathname === '/about'}
                    hoverColor={hoverColor}
                    >About</MenuLink>
                </li>
                <li>
                    <MenuLink 
                    to="/connect" 
                    active={pathname === '/connect'}
                    hoverColor={hoverColor}
                    >Connect</MenuLink>
                </li>
            </MenuList>
        </MenuWrap>
    );
}

export default Menu;

const MenuWrap = styled.div`
    padding: 10px 0;
    width: 100%;
`;

const MenuList = styled.ul`
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    margin: 0;
    padding: 0;
    a {
        color: ${props => props.color};
    }
`;

const MenuLink = styled(Link)`
    padding-bottom: 5px;
    font-family: 'Montserrat';
    text-decoration: none !important;
    font-weight: 800;
    font-size: 16px;

    @media ${device.tablet} { 
        font-size: 18px;
    }

    @media ${device.laptop} { 
        font-size: 20px;
    }

    &:hover,
    &:active {
        color: ${props => props.hoverColor};
        border-color: #EA4388;
        border-width: 3px;
    },
    color: ${props => props.active ? props.hoverColor : 'inherit'};
    border-bottom: ${props => props.active ? '3px dotted #EA4388' : 'none'};
`;