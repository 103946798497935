import React from 'react';
import styled from 'styled-components';

import Page from '../components/Page';

import { Event } from './../utils/tracking';

// #55b3b1
const Contact = ({ color, accent, closeNav }) => {

    const handleEmailClick = () => {
        // Track the the email address click event
        Event("Links", "Email - Click", "Hello at Lizzie Games");
    }

    const handleInstaClick = () => {
        // Track the the Instagram click event
        Event("Links", "Instagram - Click", "Lizzie Games on IG");
    }

    return (
        <Page title="What's Up?" gaTitle="Connect" color={color} accent={accent} closeNav={closeNav}>
            <p><Question>Wanna show some love for LizzieGames?</Question> 👏 💖 🤩</p>
            <p><Question>Wanna get the latest scoop on new games and activities?</Question> 🔔 🧩 🎯</p>

            <p>Follow <a href="https://www.instagram.com/lizzie.games/" target="_blank" rel="noopener" onClick={handleInstaClick}>@lizzie.games</a> on Instagram or drop a line to <a href="mailto:hello@lizzie.games" onClick={handleEmailClick}>hello@lizzie.games</a>!</p>
        </Page>
    );
}

export default Contact;

const Question = styled.span`
    // font-style: italic;
    font-weight: 600;
`;